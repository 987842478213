<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { isPC } from "@/utils/is-rules"
export default {
  created(){
    this.initial()
  },
  methods:{
    initial(){
      this.$store.dispatch('setIsPc',isPC())
    }
  }
}
</script>
<style lang="scss">
body,
html {
  height: 100%;
  margin: 0;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
