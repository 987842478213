<template>
  <div>
    <div class="bracelet-custome" id="bracelet-custome">
      <canvas width="400" height="400" id="canvas_1" />
      <div v-for="(item, index) in mainJewelryData" :key="index" class="spar" :style="`position: absolute;width:${mainJewelrySize * 4}px;height:${mainJewelrySize * 4
        }px;left:${item.x - mainJewelrySize * 2}px;top:${item.y - mainJewelrySize * 2
        }px;`">
        <img :src="item.data.crystalImage" width="100%" />
      </div>

      <div v-for="(item, index) in jewelryData" :key="'jewelry' + index" class="spar" :style="`position: absolute;width:${jewelrySize * 4}px;height:${jewelrySize * 4
        }px;left:${item.x - jewelrySize * 2}px;top:${item.y - jewelrySize * 2
        }px;`">
        <img :src="item.data.crystalImage" width="100%" />
      </div>

      <template v-if="step == 2">
        <div class="checkboxs-container" :style="showCheckboxsPosition">
          <input v-for="(item, index) in jewelryData" v-model="jewelryCheckBox[item.index]" :key="'jewelry' + index"
            class="spar-checkbox" type="checkbox" :style="`height:${jewelrySize * 4}px;left:${item.x - jewelrySize * 2
              }px;top:${item.y - jewelrySize * 2}px;`" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import eventBus from "@/components/eventBus.js";
export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
    //手围尺寸
    handSize: {
      type: Number,
      default: 15,
    },
  },

  watch: {
    "$store.getters.diameter": {
      handler(v) {
        this.mainJewelrySize = v;
        this.paint();
      },
    },
    "$store.getters.jewelrySize": {
      handler(v) {
        this.jewelrySize = v;
        this.isRerender = true;
        this.paint();
      },
    },
    "$store.getters.mainCurrentSpar": {
      handler(v) {
        this.mainCurrentSpar = v;
        this.paint();
      },
    },
    // "$store.getters.currentSpar": {
    //   handler(v) {
    //     if (v) {
    //       this.currentSpar = v;
    //       if (Object.keys(this.jewelryData[0].data).length == 0) {
    //         this.jewelryData = [];
    //         this.isRerender = true;
    //       }
    //       this.paint();
    //     }
    //   },
    // },
  },
  computed: {
    showCheckboxsPosition() {
      let scale, top, left;
      if (this.handSize == 15 || this.handSize == 17) {
        switch (this.jewelrySize) {
          case 6:
            scale = 1.3;
            top = 0;
            left = 0;
            break;
          case 8:
            scale = 1.4;
            top = 0;
            left = 10;
            break;
          case 10:
            scale = 1.4;
            top = 0;
            left = 10;
            break;
          case 12:
            scale = 1.5;
            top = 0;
            left = 15;
            break;
        }
      } else {
        //   scale = 1.31;
        // top = -5;
        // left = 13;
        switch (this.jewelrySize) {
          case 6:
            scale = 1.22;
            top = 0;
            left = 0;
            break;
          case 8:
            scale = 1.27;
            top = 0;
            left = 5;
            break;
          case 10:
            scale = 1.31;
            top = -5;
            left = 13;
            break;
          case 12:
            scale = 1.33;
            top = -5;
            left = 15;
            break;
        }
      }
      return `scale:${scale};top:${top}px;left:${left}px;`;
    },
  },
  created() {

  },
  data() {
    return {
      mainCurrentSpar: {},
      currentSpar: {},
      mainJewelrySize: 8,
      mainJewelryNum: 3,
      jewelrySize: 12,
      mainJewelryData: [],
      jewelryData: [],
      jewelryCheckBox: [],
      isRerender: false,
    };
  },
  mounted() {
    this.jewelrySize = this.$store.getters.jewelrySize;
    this.mainJewelryNum = this.$store.getters.mainJewelryNum;
    this.mainJewelrySize = this.$store.getters.diameter;
    this.mainCurrentSpar = this.$store.getters.mainCurrentSpar;
    this.currentSpar = this.$store.getters.currentSpar;
    this.jewelryData = this.$store.getters.jewelryData;
    if (this.jewelryData.length == 0 || this.step < 2) {
      this.jewelryData = [];
      this.isRerender = true;
    }


    this.paint();

    eventBus.$on('onSparSelect', () => {
      this.currentSpar = eventBus.currentSpar
      if (Object.keys(this.jewelryData[0].data).length == 0) {
        this.jewelryData = [];
        this.isRerender = true;
      }
      this.paint();
    })
  },
  methods: {
    createMainJewelry(data) {
      this.mainJewelryData = [];
      data.forEach((element) => {
        this.mainJewelryData.push({
          data: this.mainCurrentSpar,
          x: element.x,
          y: element.y,
          radius: this.mainCurrentSpar.diameter,
        });
      });
    },
    paint() {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      ctx.clearRect(0, 0, 400, 400);
      //绘画圆形
      this.drawCirc();
      //绘制主晶石
      let data = this.calcCircularLayout(
        this.mainJewelryNum,
        { x: 200, y: 200 },
        this.handSize
      );
      this.createMainJewelry(data);
      if (this.step > 1) {
        if (this.isRerender) {
          this.jewelryData = [];
        }

        // //绘制子珠
        let pointsData = this.calcCircularLayout(
          (this.handSize * 100 * 3.14).toFixed(0),
          { x: 200, y: 200 },
          this.handSize
        );

        this.drawTestCircular(pointsData, data);
      }
    },
    drawTestCircular(data, mainJewelryData) {
      let temp = this.group(
        data,
        Math.floor(data.length / this.mainJewelryNum)
      );
      temp = temp.splice(0, this.mainJewelryNum);
      this.drawEveryJewelry(temp, mainJewelryData);
    },
    drawCirc() {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      ctx.beginPath();
      let radius = (this.handSize * 40) / 3.14 / 2;
      ctx.arc(200, 200, radius, 0, 2 * Math.PI);
      ctx.stroke();
    },
    calcCircularLayout(nodeNum, center, radius) {
      radius = (radius * 40) / 3.14 / 2;
      var i,
        _i,
        _layouts = [];
      for (i = _i = 0; _i < nodeNum; i = ++_i) {
        var x = center.x + radius * Math.sin((2 * Math.PI * i) / nodeNum),
          y = center.y + radius * Math.cos((2 * Math.PI * i) / nodeNum);
        _layouts.push({ x: x, y: y });
      }
      return _layouts;
    },
    group(array, subNum) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subNum)));
      }
      return newArray;
    },
    drawEveryJewelry(data) {
      //判断能放多少颗珠子
      //（cm）*10 == mm  向下取整
      //子珠数量🟰 手围 * 10 - 主珠数量 * 主珠直徑 / 子珠尺寸
      let num = Math.floor(
        (this.handSize * 10 - this.mainJewelrySize * this.mainJewelryNum) /
        this.jewelrySize /
        this.mainJewelryNum
      );

      if (this.isRerender) {
        this.jewelryData = [];
      }

      if (this.jewelryCheckBox.length == 0) {
        for (let index = 0; index < num; index++) {
          this.jewelryCheckBox.push(false);
        }
      }

      // 3段
      data.forEach((item, index) => {
        // 平均每一颗珠子所占空间 包括间隙
        let avgNum = this.jewelrySize * 3.14 * 10;
        // 加一颗珠子的直徑用来居中
        let star = Math.floor(
          (this.mainJewelrySize * 10 * 3.14) / 2 + avgNum / 2
        );
        let end = Math.floor(
          data[index].length - avgNum - this.mainJewelrySize * 31.4
        );
        let arr = data[index].splice(star, end);
        avgNum = this.jewelrySize * 3.14 * 10;
        //起点 因为线段可能有空隙 检测最后一刻珠子和 下一颗主珠空隙多大，然后均分
        //线段长度 - 珠子总需长度 / 珠子数量 (arr.length - avgNum * num) / (num - 2)
        let gapDistance =
          (arr.length - this.jewelrySize * 3.14 * 10 * num) / num < 0
            ? 0
            : (arr.length - this.jewelrySize * 3.14 * 10 * num) / num;
        let startPoints =
          Math.floor(
            (arr.length -
              Number(
                (
                  Number(Math.abs(gapDistance.toFixed(0)).toFixed(0)) +
                  avgNum * (num - 1 == 0 ? 1 : num - 1) +
                  gapDistance * (num - 1 == 0 ? 1 : num - 1) -
                  1
                ).toFixed(0)
              )) /
            2
          ) < 0
            ? 0
            : Math.floor(
              (arr.length -
                Number(
                  (
                    Number(Math.abs(gapDistance.toFixed(0)).toFixed(0)) +
                    avgNum * (num - 1 == 0 ? 1 : num - 1) +
                    gapDistance * (num - 1 == 0 ? 1 : num - 1) -
                    1
                  ).toFixed(0)
                )) /
              2
            );
        for (let j = 0; j < num; j++) {
          let k =
            j == 0
              ? (Math.abs(gapDistance.toFixed(0)) + startPoints).toFixed(0)
              : (
                Number(
                  (Math.abs(gapDistance.toFixed(0)) + startPoints).toFixed(0)
                ) +
                avgNum * j +
                gapDistance * j -
                2
              ).toFixed(0);
          let temp = arr[k];
          // * 2是因为需要1px = 4mm n*2 = 4mm下的半径
          if (this.isRerender) {
            this.jewelryData.push({
              data: this.currentSpar,
              x: temp.x,
              y: temp.y,
              radius: this.currentSpar.diameter,
              index: j,
            });
          } else {
            //子珠只能将勾选的重渲染
            let arr = [
              this.jewelryCheckBox,
              this.jewelryCheckBox,
              this.jewelryCheckBox,
            ];
            // console.log(arr, j, index)
            if (arr[index][j]) {
              this.jewelryData[index * num + j] = {
                data: this.currentSpar,
                x: temp.x,
                y: temp.y,
                radius: this.currentSpar.diameter,
                index: j,
              };
            }
          }
          // let checkButtonScale = (this.handSize + 6) / this.handSize;
          // Math.floor(index * k * checkButtonScale)
          // var ctx = document.getElementById("canvas_1").getContext("2d");
          // ctx.beginPath();
          // // (index+1) * k *scale
          // let test = this.chechkButtons[(index+1)*k];
          // ctx.arc(test.x, test.y, this.jewelrySize * 2, 0, 2 * Math.PI);
          // ctx.stroke();
        }
      });
      this.$store.dispatch("setJewelryData", this.jewelryData);
      eventBus.bracelets = this.jewelryData;
      eventBus.$emit("getJewelryData");
      this.isRerender = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bracelet-custome {
  width: 400px;
  height: 400px;
  position: relative;
  user-select: none;
}

.spar {
  // background-color: red;
  border-radius: 50%;
  overflow: hidden;
}

#canvas_1 {
  top: 0;
  left: 0;
  position: absolute;
}

.checkboxs-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.spar-checkbox {
  position: absolute;
}
</style>