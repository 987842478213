<template>
  <div class="make-bracelet-step">
    <makeBraceletTitle></makeBraceletTitle>
    <div v-if="!$store.getters.isPc" class="make-bracelet-phone">

    <div class="title-box">
      <titleImg top="選擇子珠" :bottom="`直徑尺寸`"></titleImg>
    </div>
    <el-row :gutter="20" class="button-group step-button-group">
      <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(6)"
        ><span class="step-text">·6mm·</span>
        <img
          v-if="jewelrySize == 6"
          src="@/assets/images/step_3-button-selected.png"
        />
        <img v-else src="@/assets/images/step_3-button.png" />
      </el-col>
      <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(8)"
        ><span class="step-text">·8mm·</span>
        <img
          v-if="jewelrySize == 8"
          src="@/assets/images/step_3-button-selected.png"
        />
        <img v-else src="@/assets/images/step_3-button.png" />
      </el-col>
      <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(10)"
        ><span class="step-text">·10mm·</span
        ><img
          v-if="jewelrySize == 10"
          src="@/assets/images/step_3-button-selected.png" />
        <img v-else src="@/assets/images/step_3-button.png"
      /></el-col>
      <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(12)"
        ><span class="step-text">·12mm·</span
        ><img
          v-if="jewelrySize == 12"
          src="@/assets/images/step_3-button-selected.png" />
        <img v-else src="@/assets/images/step_3-button.png"
      /></el-col>
    </el-row>
    <div class="title-preview">
      <titleImg
        top="手链預覽"
        :bottom="`${braceletType($store.getters.mainJewelryNum)}（手圍${$store.getters.preview}cm）`"
      ></titleImg>
    </div>
    <div class="make-bracelet-content">
      <img class="make-bracelet-content-img-sheji" width="100%" src="@/assets/images/sheji.png" />
      <bracelet class="make-bracelet-content-bracelet" :handSize="handsize" :step="2" />
    </div>
    <div class="title-preview">
      <titleImg top="水晶能量" bottom="選擇"></titleImg>
    </div>
    <div class="make-bracelet-hand-around">
      <chooseCrystal :step="3"></chooseCrystal>
    </div>
    <div class="make-bracelet-hand-around">
      <stepIntroduce :step="3"></stepIntroduce>
    </div>
    <div class="make-bracelet-hand-around">
       <numberCombined :step="3"></numberCombined>
    </div>
    <div class="button-group-box">
      <el-row :gutter="50" class="button-group">
        <el-col :span="12"
          ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
        /></el-col>
        <el-col :span="12"
          ><img @click="onNextStep" src="@/assets/images/next-step.png"
        /></el-col>
      </el-row>
    </div>
    </div>
   
    <div v-else class="make-bracelet-pc">
        <el-row :gutter="20">
          <el-col :span="6">
             <div class="title-box">
                <titleImg top="選擇子珠" :bottom="`直徑尺寸`"></titleImg>
              </div>
              <el-row :gutter="20" class="button-group step-button-group">
                <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(6)"
                  ><span class="step-text">·6mm·</span>
                  <img
                    v-if="jewelrySize == 6"
                    src="@/assets/images/step_3-button-selected.png"
                  />
                  <img v-else src="@/assets/images/step_3-button.png" />
                </el-col>
                <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(8)"
                  ><span class="step-text">·8mm·</span>
                  <img
                    v-if="jewelrySize == 8"
                    src="@/assets/images/step_3-button-selected.png"
                  />
                  <img v-else src="@/assets/images/step_3-button.png" />
                </el-col>
                <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(10)"
                  ><span class="step-text">·10mm·</span
                  ><img
                    v-if="jewelrySize == 10"
                    src="@/assets/images/step_3-button-selected.png" />
                  <img v-else src="@/assets/images/step_3-button.png"
                /></el-col>
                <el-col :span="6" class="step-list" @click.native="onSetJewelrySize(12)"
                  ><span class="step-text">·12mm·</span
                  ><img
                    v-if="jewelrySize == 12"
                    src="@/assets/images/step_3-button-selected.png" />
                  <img v-else src="@/assets/images/step_3-button.png"
                /></el-col>
              </el-row>
              <div class="title-preview">
                <titleImg top="水晶能量" bottom="選擇"></titleImg>
              </div>
              <div class="make-bracelet-hand-around">
                <chooseCrystal :step="3"></chooseCrystal>
              </div>
          </el-col>
          <el-col :span="10">
             <div class="title-preview">
                <titleImg
                  top="手链預覽"
                  :bottom="`${braceletType($store.getters.mainJewelryNum)}（手圍${$store.getters.preview}cm）`"
                ></titleImg>
              </div>
              <div class="make-bracelet-content">
                <img class="make-bracelet-content-img-sheji" width="100%" src="@/assets/images/sheji.png" />
                <bracelet class="make-bracelet-content-bracelet" :handSize="handsize" :step="2" />
              </div>
          </el-col>
          <el-col :span="8">
               <div class="make-bracelet-hand-around">
                  <stepIntroduce :step="3"></stepIntroduce>
                </div>
                <div class="make-bracelet-hand-around">
                  <numberCombined :step="3"></numberCombined>
                </div>
          </el-col>
        </el-row>
        <div class="button-group-box">
          <el-row :gutter="50" type="flex" align="middle" justify="center" class="button-group">
            <el-col :span="5"
              ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
            /></el-col>
            <el-col :span="5"
              ><img @click="onNextStep" src="@/assets/images/next-step.png"
            /></el-col>
          </el-row>
        </div>
    </div>
  </div>
</template>

<script>
import makeBraceletTitle from "./makeBraceletTitle.vue";
import numberCombined from "../components/numberCombined.vue";
import titleImg from "./titleImg.vue";
import stepIntroduce from "./stepIntroduce.vue";
import chooseCrystal from "./chooseCrystal.vue";
import bracelet from "../../createBracelets/bracelet.vue";
export default {
  components: {makeBraceletTitle, titleImg, stepIntroduce, chooseCrystal, bracelet,numberCombined },
  computed: {
    handsize() {
      return this.$store.getters.preview;
    },
    jewelrySize() {
      return this.$store.getters.jewelrySize;
    },
  },
  methods: {
    onSetJewelrySize(v) {
      this.$store.dispatch("setJewelrySize", v);
      this.$forceUpdate();
    },
    onPreviousStep() {
      this.$store.dispatch("setStep", 1);
    },
    onNextStep() {
      this.$store.dispatch("setStep", 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.make-bracelet-step {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/bg_4.png");
  background-size: 100%;
  overflow: hidden;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.make-bracelet-phone{
  display: none;
}
.title-preview {
  margin: 1.25rem 0;
}
.make-bracelet-img {
  width: 12.5rem;
}
.button-group-box {
  overflow: hidden;
  padding: 1.25rem;
}
.step-button-group {
  padding: 0 1.25rem;
  margin-top: 1.25rem;
}
.step-list {
  position: relative;
}
.step-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.make-bracelet-hand-around {
  margin: 0 1.25rem;
  margin-bottom: 1.25rem;
}
.make-bracelet-content-bracelet{
  margin: 0 auto !important;
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, 0);
}
.make-bracelet-content {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border: 2px dashed;
  height: 400px;
  position: relative;
}
.make-bracelet-content-img-sheji{
  position: absolute;
  width: 3.125rem;
  top: 0.625rem;
  left: 0.625rem;
}
.button-group img {
  display: block;
  width: 100%;
}
.button-group-box img {
  display: block;
  width: 12.5rem;
  margin: 0 auto;
}
@media (min-width: $phoneMinWidth) and (max-width: $phoneMaxWidth) {
.make-bracelet-phone{
  display: block;
}
.make-bracelet-pc{
  display: none;
}
.title-preview {
  margin: 1.25rem 0;
}
.make-bracelet-img {
  width: 12.5rem;
}
.button-group-box {
  overflow: hidden;
  padding: 1.25rem;
}
.step-button-group {
  padding: 0 1.25rem;
  margin-top: 1.25rem;
}
.step-list {
  position: relative;
}
.step-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.make-bracelet-hand-around {
  margin: 0 1.25rem;
  margin-bottom: 1.25rem;
}

.make-bracelet-content {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border: 2px dashed;
  height: 400px;
}
.button-group img {
  display: block;
  width: 100%;
}
}
</style>