export const mixin = {
  methods: {
    braceletType(v){
      switch (v) {
         case '1':
           return '一芒星'
         case '2':
           return '兩芒星'
         case '3':
           return '三芒星'
         case '4':
           return '四芒星'
         case '5':
           return '五芒星'
         case '6':
           return '六芒星'
         case '8':
           return '八芒星'
         case '9':
           return '其他'
       }
    }
  }
}
