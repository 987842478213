
///客户端是pc端还是移动端
export function isPC(){  
  const flag = navigator.userAgent.includes('Mobile')
  return !flag
}
// export function isPC(){  
//   const flag = navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|harmony|HarmonyOS|iPod|Ios|iPad|huawei|Android|oppo|vivo|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   )
//   return !flag
// }

///数字小数点

export function toFixed(number, m) {
    if (typeof number !== "number") {
      throw new Error("number不是数字");
    }
    let result = Math.round(Math.pow(10, m) * number) / Math.pow(10, m);
    result = String(result);
    if (result.indexOf(".") == -1) {
      if (m != 0) {
        result += ".";
        result += new Array(m + 1).join("0");
      }
    } else {
      let arr = result.split(".");
      if (arr[1].length < m) {
        arr[1] += new Array(m - arr[1].length + 1).join("0");
      }
      result = arr.join(".");
    }
    return result;
  }
  
  export function integralComputation(point, pet){
       
    return  Math.round(point / pet) 
     
  }
  export function  Mathround(value){
       
    return  parseInt(value) 
     
  }
  
  ///判断输入值是否为正整数
export function isPositiveInteger(s){
  
  if((/(^[1-9]\d*$)/.test(s))) {
     return  true
  } else {
    return false
  }
}    

///转换为了大写字符
export function stringToUpperCase(value){
   return value.toUpperCase()
}
