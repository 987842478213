<template>
  <div class="make-bracelet-step">
    <makeBraceletTitle></makeBraceletTitle>
    <div v-if="!$store.getters.isPc" class="make-bracelet-phone">
      <div class="title-box title-preview">
        <titleImg
          top="手链預覽"
          :bottom="`${braceletType($store.getters.mainJewelryNum)}（手圍${$store.getters.preview}cm）`"
        ></titleImg>
      </div>
      <div class="make-bracelet-content">
        <bracelet class="make-bracelet-content-bracelet" :handSize="handsize" :step="3" />
      </div>
      <div class="title-preview">
        <titleImg top="金屬裝飾配件" bottom="選擇"></titleImg>
      </div>
      <div class="make-bracelet-hand-around">
        <el-row :gutter="20" class="button-group step-button-group">
          <el-col :span="8" class="step-list" @click.native="onSetAccessories(0)"
            ><span class="step-text">·無·</span>
            <img v-if="handmadePart == 0" src="@/assets/images/step_4-choose-button.png" />
            <img v-else  src="@/assets/images/step_4-button.png" />
            
          </el-col>
          <el-col :span="8" class="step-list" @click.native="onSetAccessories(1)"
            ><span class="step-text">·少·</span>
            <img v-if="handmadePart == 1" src="@/assets/images/step_4-choose-button.png" />
            <img v-else src="@/assets/images/step_4-button.png" />
          
          </el-col>
          <el-col :span="8" class="step-list" @click.native="onSetAccessories(2)"
            ><span class="step-text">·多·</span>
            <img v-if="handmadePart == 2" src="@/assets/images/step_4-choose-button.png" />
            <img v-else src="@/assets/images/step_4-button.png" />
            
          </el-col>
        </el-row>
        <descriptionPart :remark="remark"></descriptionPart>
      </div>
      <div class="make-bracelet-hand-around">
        <stepIntroduce  :step="3"></stepIntroduce>
      </div>
      <div class="make-bracelet-hand-around bracelets-number">
        <el-row class="bracelets-number-content" type="flex" align="middle">
          <el-col :span="12"><div>請選擇手鏈數量</div></el-col>
          <el-col :span="12"
            ><el-input-number
              @change="onChangeNumber"
              v-model="num"
              :min="1"
              :max="10"
              label="描述文字"
            ></el-input-number
          ></el-col>
        </el-row>
        <img
          class="bracelets-number-img"
          src="@/assets/images/bracelets-number.png"
        />
      </div>
      <div class="make-bracelet-hand-around">
        <!-- <div class="number-combined">
          <img
            class="bracelets-number-img"
            src="@/assets/images/combined-top.png"
          />
          <div class="number-combined-content">
            <div class="number-combined-list">
              <el-row type="flex" justify="space-between">
                <el-col :span="8">合計</el-col>
                <el-col :span="16" style="text-align: right"
                  >{{ getBraceletPrice }} {{ getBraceletPromotionPrice }}</el-col
                >
              </el-row>
            </div>
            <div class="number-combined-list" v-for="(item,index) in getBracelet()" :key="index">
                <el-row :gutter="20">
                  <el-col :span="6">{{item.materialId}}</el-col>
                  <el-col :span="6">{{item.size}}</el-col>
                  <el-col :span="6">{{item.number}}</el-col>
                </el-row>
            </div>
          </div>
          <img
            class="bracelets-number-img"
            src="@/assets/images/combined-bottom.png"
          />
        </div> -->
        <numberCombined :step="3"></numberCombined>
      </div>
      <div class="button-group-box">
        <el-row :gutter="50" class="button-group">
          <el-col :span="12"
            ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
          /></el-col>
          <el-col :span="12"
            ><img @click="onNextStep" src="@/assets/images/next-step.png"
          /></el-col>
        </el-row>
      </div>
    </div>
    <div v-else class="make-bracelet-pc">
        <el-row :gutter="20">
          <el-col :span="8">
              <div class="title-preview">
                <titleImg top="金屬裝飾配件" bottom="選擇"></titleImg>
              </div>
              <div class="make-bracelet-hand-around">
                <el-row :gutter="20" class="button-group step-button-group">
                  <el-col :span="8" class="step-list" @click.native="onSetAccessories(0)"
                    ><span class="step-text">·無·</span>
                    <img v-if="handmadePart == 0" src="@/assets/images/step_4-choose-button.png" />
                    <img v-else  src="@/assets/images/step_4-button.png" />
                    
                  </el-col>
                  <el-col :span="8" class="step-list" @click.native="onSetAccessories(1)"
                    ><span class="step-text">·少·</span>
                    <img v-if="handmadePart == 1" src="@/assets/images/step_4-choose-button.png" />
                    <img v-else src="@/assets/images/step_4-button.png" />
                  
                  </el-col>
                  <el-col :span="8" class="step-list" @click.native="onSetAccessories(2)"
                    ><span class="step-text">·多·</span>
                    <img v-if="handmadePart == 2" src="@/assets/images/step_4-choose-button.png" />
                    <img v-else src="@/assets/images/step_4-button.png" />
                    
                  </el-col>
                </el-row>
                <descriptionPart :remark="remark"></descriptionPart>
              </div>
          </el-col>
          <el-col :span="8">
             <div class="title-box title-preview">
              <titleImg
                top="手链預覽"
                :bottom="`${braceletType($store.getters.mainJewelryNum)}（手圍${$store.getters.preview}cm）`"
              ></titleImg>
            </div>
            <div class="make-bracelet-content">
              <bracelet class="make-bracelet-content-bracelet" :handSize="handsize" :step="3" />
            </div>
          </el-col>
          <el-col :span="8">
              <!-- <div class="make-bracelet-hand-around">
              <stepIntroduce></stepIntroduce>
              </div>
              <div class="make-bracelet-hand-around">
              <div class="number-combined">
                <img
                  class="bracelets-number-img"
                  src="@/assets/images/combined-top.png"
                />
                <div class="number-combined-content">
                  <div class="number-combined-list">
                    <el-row type="flex" justify="space-between">
                      <el-col :span="8">合計</el-col>
                      <el-col :span="16" style="text-align: right"
                        >{{ getBraceletPrice }} {{ getBraceletPromotionPrice }}</el-col
                      >
                    </el-row>
                  </div>
                  <div class="number-combined-list" v-for="(item,index) in getBracelet()" :key="index">
                      <el-row :gutter="20">
                        <el-col :span="6">{{item.materialId}}</el-col>
                        <el-col :span="6">{{item.size}}</el-col>
                        <el-col :span="6">{{item.number}}</el-col>
                      </el-row>
                  </div>

                </div>
                <img
                  class="bracelets-number-img"
                  src="@/assets/images/combined-bottom.png"
                />
              </div>
             </div> -->
              <div class="make-bracelet-hand-around">
                  <stepIntroduce :step="3"></stepIntroduce>
              </div>
              <div class="make-bracelet-hand-around">
                  <numberCombined :step="3"></numberCombined>
              </div>
          </el-col>
        </el-row>
        <div class="button-group-box">
        <el-row :gutter="50" type="flex" align="middle" justify="center" class="button-group">
          <el-col :span="5"
            ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
          /></el-col>
          <el-col :span="5"
            ><img @click="onNextStep" src="@/assets/images/next-step.png"
          /></el-col>
        </el-row>
        </div>
    </div>
  </div>
</template>

<script>
import numberCombined from "../components/numberCombined.vue";
import makeBraceletTitle from "./makeBraceletTitle.vue";
import titleImg from "./titleImg.vue";
import html2canvas from "html2canvas";
import stepIntroduce from "./stepIntroduce.vue";
import descriptionPart from "./descriptionPart.vue";
import bracelet from "../../createBracelets/bracelet.vue";
import { createOrders } from "@/api/material";
import { file } from "@/api/files.js";
export default {
  components: {makeBraceletTitle, titleImg, stepIntroduce, descriptionPart, bracelet,numberCombined },
  data() {
    return {
      remark: "",
    };
  },
  computed: {
    handsize() {
      return this.$store.getters.preview;
    },
    diameter() {
      return this.$store.getters.diameter;
    },
    handmadePart() {
      return this.$store.getters.handmadePart;
    },
    num: {
      get() {
        return this.$store.getters.number;
      },
      set(newValue) {
        return newValue;
      }
    },
    // getBraceletPrice() {
    //   let getBracelet = this.getBracelet();
    //   let price = 0;
    //   getBracelet.forEach((item) => {
    //     price += item.price * item.number;
    //   });
    //   return price;
    // },
    // getBraceletPromotionPrice() {
    //   let getBracelet = this.getBracelet();
    //   console.log(getBracelet);
    //   let price = 0;
    //   getBracelet.forEach((item) => {
    //     price += item.currentPromotionPrice * item.number;
    //   });
    //   return price;
    // },
  },
  methods: {
    onChangeNumber(v){
      console.log(v)
      this.$store.dispatch("setNumber", v);
    },
    onSetAccessories(v) {
      // HandmadePart
      this.$store.dispatch("setHandmadePart", v);
      this.$forceUpdate();
    },
    onPreviousStep() {
      this.$store.dispatch("setStep", 2);
    },
    base64toFile(dataurl, filename = "file") {
      if (dataurl == null) {
        return null;
      }
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    getBracelet() {
      let mainJewelryData = this.$store.getters.mainCurrentSpar;
      let braceletType = this.$store.getters.mainJewelryNum;
      let braceletMaterials = [
        {
          type: 0,
          size: mainJewelryData.diameter,
          materialId: mainJewelryData.id,
          number: braceletType,
          price: mainJewelryData.price,
          describe: mainJewelryData.describe,
          name: mainJewelryData.name,
          currentPromotionPrice: mainJewelryData.currentPromotionPrice,
          index: 0,
        },
      ];

      let jewelryData = this.$store.getters.jewelryData;
      const group = {};
      jewelryData.forEach(function (o) {
        const temp = JSON.stringify(o.data.id);
        group[temp] = group[temp] || [];
        group[temp].push(o);
      });

      let keys = Object.keys(group);

      for (let index = 0; index < keys.length; index++) {
        braceletMaterials.push({
          type: 1,
          size: group[keys[index]][0].data.diameter,
          describe: group[keys[index]][0].data.describe,
          materialId: group[keys[index]][0].data.id,
          number: group[keys[index]].length,
          index: index + 1,
          currentPromotionPrice:
            group[keys[index]][0].data.currentPromotionPrice,
          name: group[keys[index]][0].data.name,
          price: group[keys[index]][0].data.price,
        });
      }

      return braceletMaterials;
    },
    async onNextStep() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 完成按钮提交数据
      let braceletType = this.$store.getters.mainJewelryNum;
      let wristSize = this.$store.getters.preview;
      let handmadePart = this.$store.getters.handmadePart;
      let remark = this.remark;
      let imgUrl;
      let braceletImgBase64 = await html2canvas(
        document.querySelector("#canvas_1"),
        {
          useCORS: true,
          scale: 2,
          // window.devicePixelRatio是设备像素比
          dpi: window.devicePixelRatio * 2,
        }
      ).then(function (canvas) {
        return canvas.toDataURL("image/png");
      });

      const formData = new FormData();
      formData.append("file", this.base64toFile(braceletImgBase64));

      await file(formData).then((result) => {
        imgUrl = result.data;
      });

      let braceletQuantity = this.$store.getters.number;
      let braceletMaterials = this.getBracelet();
      let payPrice = 0;

      braceletMaterials.forEach((item) => {
        payPrice += item.currentPromotionPrice * item.number;
      });

      let data = {
        braceletType,
        wristSize,
        handmadePart,
        remark,
        imageUrl: imgUrl,
        braceletQuantity,
        payPrice,
        braceletMaterials,
      };

      createOrders(data).then((result) => {
        loading.close();
        window.location.href = result.data.checkoutUrl;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.make-bracelet-step {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/step_4_bg.png");
  background-size: 100%;
  overflow: hidden;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.make-bracelet-phone{
  display: none;
}
.title-preview {
  margin: 1.25rem 0;
}
.make-bracelet-img {
  width: 12.5rem;
}
.button-group-box {
  overflow: hidden;
  padding: 1.25rem;
}
.step-button-group {
  padding: 0 1.25rem;
  margin-top: 1.25rem;
  color: #fff;
  margin-bottom: 1.25rem;
}
.step-list {
  position: relative;
}
.step-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.make-bracelet-hand-around {
  margin: 0 1.25rem;
  margin-bottom: 1.25rem;
}
.make-bracelet-content-bracelet{
  margin: 0 auto !important;
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, 0);
}
.make-bracelet-content {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border: 2px dashed;
  height: 400px;
}
.button-group img {
  display: block;
  width: 100%;
}
.button-group-box img {
  display: block;
  width: 12.5rem;
  margin: 0 auto;
}
.bracelets-number-img {
  display: block;
  width: 100%;
}
.bracelets-number {
  position: relative;
}
.bracelets-number-content {
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.number-combined {
  border-radius: 0.625rem;
  padding: 1rem;
  background-color: #fff8f1;
}
.number-combined-content {
  border-left: 2px solid #b7964b;
}
.number-combined-content {
  padding-left: 1.25rem;
}
.number-combined-list {
  padding: 8px 0;
  border-bottom: 2px solid #b7964b;
  border-image: url("~@/assets/images/number-combined-list-bg.png") 27;
}
#canvas_1 {
  position: relative;
}
::v-deep {
  .el-input-number {
    width: 100%;
    background-image: url("~@/assets/images/number_bg.png");
    background-size: 100%;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background-color: rgb(0 0 0 / 0%);
    border: 0;
    color: #000;
    font-size: 1.25rem;
  }
  .el-input-number__decrease {
    border-right: 2px solid #fbd255;
  }
  .el-input-number__increase {
    border-left: 2px solid #fbd255;
  }
  .el-input-number .el-input__inner {
    background-color: rgb(0 0 0 / 0%);
    border: 0;
  }
}
@media (min-width: $phoneMinWidth) and (max-width: $phoneMaxWidth) {
.make-bracelet-pc{
  display: none;
}
.make-bracelet-phone{
  display: block;
}

.title-preview {
  margin: 1.25rem 0;
}
.make-bracelet-img {
  width: 12.5rem;
}
.button-group-box {
  overflow: hidden;
  padding: 1.25rem;
}
.step-button-group {
  padding: 0 1.25rem;
  margin-top: 1.25rem;
  color: #fff;
  margin-bottom: 1.25rem;
}
.step-list {
  position: relative;
}
.step-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.make-bracelet-hand-around {
  margin: 0 1.25rem;
  margin-bottom: 1.25rem;
}
.make-bracelet-content {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border: 2px dashed;
  height: 400px;
}
.button-group img {
  display: block;
  width: 100%;
}
.bracelets-number-img {
  display: block;
  width: 100%;
}
.bracelets-number {
  position: relative;
}
.bracelets-number-content {
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.number-combined {
  border-radius: 0.625rem;
  padding: 1rem;
  background-color: #fff8f1;
}
.number-combined-content {
  border-left: 2px solid #b7964b;
}
.number-combined-content {
  padding-left: 1.25rem;
}
.number-combined-list {
  padding: 8px 0;
  border-bottom: 2px solid #b7964b;
  border-image: url("~@/assets/images/number-combined-list-bg.png") 27;
}
#canvas_1 {
  position: relative;
}
::v-deep {
  .el-input-number {
    width: 100%;
    background-image: url("~@/assets/images/number_bg.png");
    background-size: 100%;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background-color: rgb(0 0 0 / 0%);
    border: 0;
    color: #000;
    font-size: 1.25rem;
  }
  .el-input-number__decrease {
    border-right: 2px solid #fbd255;
  }
  .el-input-number__increase {
    border-left: 2px solid #fbd255;
  }
  .el-input-number .el-input__inner {
    background-color: rgb(0 0 0 / 0%);
    border: 0;
  }
}
}
</style>