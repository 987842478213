import Vue from 'vue'
import Vuex from 'vuex'
import step from './modules/step'
import badge from './modules/badge'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        step,
        badge,
    },
    getters,
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})

export default store