<template>
     <div class="make-bracelet-title">
            <Header></Header>
            <div class="make-bracelet-title-box">
                    <el-row :gutter="20" class="make-bracelet-row">
                    <el-col :span="6" class="make-bracelet-row-title">
                        <div class="make-bracelet-row-title-text" :class="{'active':active ==0}">
                            <div>選擇尺寸</div>
                        </div> 
                        <img v-if="active ==0" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected1.png"/>
                        <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button1.png"/>
                    </el-col>
                    <el-col :span="6" class="make-bracelet-row-title">
                        <div class="make-bracelet-row-title-text" :class="{'active':active ==1}">
                            <div>選擇主珠</div>
                        </div> 
                        <img v-if="active ==1" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected2.png"/>
                        <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button2.png"/>
                    </el-col>
                    <el-col :span="6" class="make-bracelet-row-title">
                        <div class="make-bracelet-row-title-text" :class="{'active':active ==2}">
                            <div>選擇子珠</div>
                        </div> 
                        <img v-if="active ==2" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected3.png"/>
                        <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button3.png"/>
                    </el-col>
                    <el-col :span="6" class="make-bracelet-row-title">
                        <div class="make-bracelet-row-title-text" :class="{'active':active ==3}">
                            <div>
                                <div>選擇金屬</div>
                                <div>裝飾配件</div>
                            </div>
                        </div> 
                        <img v-if="active ==3" class="make-bracelet-title-bg" src="@/assets/images/title-button-selected4.png"/>
                        <img v-else class="make-bracelet-title-bg" src="@/assets/images/title-button4.png"/>
                    </el-col>
                    </el-row>
                    <img class="make-bracelet-title-bg" src="@/assets/images/make-bracelet-title-bg.png"/>
            </div>
     </div> 
</template>

<script>
import Header from "@/layout/Header";
    export default {
         components: {Header},
         computed:{
            active(){
               return this.$store.getters.step
            },
            preview(){
               return this.$store.getters.preview
            },
        },
    }
</script>

<style lang="scss" scoped>
.make-bracelet{
    position: relative;
}
.make-bracelet-title{
    // position: absolute;
    padding: 1rem;
    margin: 0 auto;
}
.make-bracelet-title-bg{
    display: block;
    width: 100%;
}
.make-bracelet-row{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     width: 95%;
}

.make-bracelet-row-title{
    position: relative;
}
.make-bracelet-title-box{
    position: relative;
    width: 70%;
    margin: 0 auto;
}
.make-bracelet-row-title-text{
    position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     font-size: 1.8rem;
     width: 100%;
     text-align: center;
     color: #3390AA;
}
.active{
    color: #fff;
}

@media (min-width: $phoneMinWidth) and (max-width: $phoneMaxWidth) {
    .make-bracelet-row-title-text{
      transform-origin: 0 0 0;
      font-size: 0.5625rem;
      left: 0;
      top: 50%;
      transform: translate(12%, -40%) scale(0.8);
    }
    .make-bracelet-title-box{
        width: 100%;
    }
}
</style>