const getters = {
    initialBadge: state => state.badge.initial,
    avatar0Base64: state => state.badge.avatar0Base64,
    avatar1Base64: state => state.badge.avatar1Base64,
    avatar2Base64: state => state.badge.avatar2Base64,
    avatar3Base64: state => state.badge.avatar3Base64,
    phone: state => state.badge.phone,
    name: state => state.badge.name,
    isShowStep2: state => state.step.isShowStep2,
    step: state => state.step.step,
    preview: state => state.step.preview,
    diameter: state => state.step.diameter,
    step2label: state => state.step.step2label,
    step3label: state => state.step.step3label,
    currentSpar: state => state.step.currentSpar,
    number: state => state.step.number,
    isPc: state => state.step.isPc,
    crystalList3: state => state.step.crystalList3,
    crystalList2: state => state.step.crystalList2,
    //主珠数 几点式
    mainJewelryNum: state => state.step.mainJewelryNum,
    mainCurrentSpar:state => state.step.mainCurrentSpar,
    jewelrySize: state => state.step.jewelrySize,
    handmadePart: state => state.step.handmadePart, 
    jewelryData: state => state.step.jewelryData
}
export default getters