<template>
  <div>
    <div class="number-combined">
      <img class="bracelets-number-img" src="@/assets/images/combined-top.png" />
      <div class="number-combined-content">
        <div class="number-combined-list">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">合計</el-col>
            <el-col class="bracelet-price-text" style="text-align: right">{{ getBraceletPrice }}日元 <span
                class="promotion-price-text">{{ getBraceletPromotionPrice }}日元</span>(含税) </el-col>
          </el-row>
        </div>
        <div class="number-combined-list" v-for="(item, index) in braceletMaterials" :key="index">
          <el-row :gutter="4">
            <el-col :span="10">{{ item.name }}</el-col>
            <el-col :span="10">直徑:{{ item.size }}mm</el-col>
            <el-col :span="4">{{ item.number }}個</el-col>
          </el-row>
        </div>
      </div>
      <img class="bracelets-number-img" src="@/assets/images/combined-bottom.png" />
    </div>
  </div>
</template>

<script>
import eventBus from "@/components/eventBus.js";
export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      braceletMaterials: []
    }
  },
  created() {
    eventBus.$on('getJewelryData', () => {
      this.bracelets = eventBus.bracelets
      this.$store.dispatch("setJewelryData", this.bracelets);
      this.braceletMaterials = this.getBracelet()
    })
  },
  computed: {
    getBraceletPrice() {
      let getBracelet = this.getBracelet();
      let price = 0;
      getBracelet.forEach((item) => {
        price += item.price * item.number;
      });
      return price;
    },
    getBraceletPromotionPrice() {
      let getBracelet = this.getBracelet();
      let price = 0;
      getBracelet.forEach((item) => {
        price += item.currentPromotionPrice * item.number;
      });
      return price;
    },
  },
  methods: {
    getBracelet() {
      let mainJewelryData = this.$store.getters.mainCurrentSpar;
      let braceletType = this.$store.getters.mainJewelryNum;
      let braceletMaterials = [
        {
          type: 0,
          size: mainJewelryData.diameter,
          materialId: mainJewelryData.id,
          number: braceletType * this.$store.getters.number,
          price: mainJewelryData.price,
          describe: mainJewelryData.describe,
          name: mainJewelryData.name,
          currentPromotionPrice: mainJewelryData.currentPromotionPrice,
          index: 0,
        },
      ];
      if (this.step == 3) {
        let jewelryData = this.$store.getters.jewelryData;
        const group = {};
        jewelryData.forEach(function (o) {
          const temp = JSON.stringify(o.data.id);
          group[temp] = group[temp] || [];
          group[temp].push(o);
        });

        let keys = Object.keys(group);

        for (let index = 0; index < keys.length; index++) {
          braceletMaterials.push({
            type: 1,
            size: group[keys[index]][0].data.diameter,
            describe: group[keys[index]][0].data.describe,
            materialId: group[keys[index]][0].data.id,
            number: group[keys[index]].length * this.$store.getters.number,
            index: index + 1,
            currentPromotionPrice:
              group[keys[index]][0].data.currentPromotionPrice,
            name: group[keys[index]][0].data.name,
            price: group[keys[index]][0].data.price,
          });
        }
      }
      return braceletMaterials;
    },
  },
};
</script>

<style lang="scss" scoped>
.number-combined {
  //   width: 100%;
  border-radius: 0.625rem;
  padding: 1rem;
  background-color: #fff8f1;
}

.number-combined-content {
  border-left: 2px solid #b7964b;
}

.number-combined-content {
  padding-left: 1.25rem;
}

.number-combined-list {
  padding: 8px 0;
  border-bottom: 2px solid #b7964b;
  border-image: url("~@/assets/images/number-combined-list-bg.png") 27;
}

.button-group img {
  display: block;
  width: 100%;
}

.bracelets-number-img {
  display: block;
  width: 100%;
}

.bracelet-price-text {
  color: #c8c5c5;
  font-size: 0.875rem;
}

.promotion-price-text {
  color: red;
  font-size: 1.25rem;
}
</style>