import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import xyUi from './components/vueComp/xyUi.js'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss'
import ElementUi from 'element-ui'
import * as filters from './filters'
import { mixin } from '@/mixin/index'
Vue.use(ElementUi)
Vue.use(xyUi);
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.mixin({
  ...mixin
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
