<template>
  <div>
    手围大小 (15,17,19,21)
    <el-input v-model="handSize" />
    几芒星 (1,2,3,4,5,6,8)
    <el-input v-model="mainJewelryNum" />
    主珠大小大小 (8,10,12)
    <el-input v-model="mainJewelrySize" />
    子珠大小 (6，8，10，12)
    <el-input v-model="jewelrySize" />
    <div
      id="test"
      :style="`width:${400 * zoom}px;height:${
        400 * zoom
      }px;position: relative;margin:100px auto;`"
    >
      <canvas width="400" height="400" id="canvas_1" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zoom: 1,
      mainJewelrySize: 12,
      mainJewelryNum: 8,
      jewelrySize: 6,
      handSize: 15,
    };
  },
  mounted() {
    this.paint();
  },
  watch: {
    handSize() {
      this.paint();
    },
    mainJewelrySize() {
      this.paint();
    },
    mainJewelryNum() {
      this.paint();
    },
    jewelrySize() {
      this.paint();
    },
  },
  methods: {
    //生成360个点，每个点画个圆，例碰到上一个圆 则跳过该点，最后得出 真正绘画出的点数。 等除
    paint() {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      ctx.clearRect(0, 0, 400, 400);

      //绘画圆形
      this.drawCirc();
      //绘制主晶石
      let data = this.calcCircularLayout(
        this.mainJewelryNum,
        { x: 200, y: 200 },
        this.handSize
      );
      this.drawMainJewelry(data);
      // //绘制子珠
      let pointsData = this.calcCircularLayout(
        (this.handSize * 100 * 3.14).toFixed(0),
        { x: 200, y: 200 },
        this.handSize
      );
      this.drawTestCircular(pointsData, data);
    },
    group(array, subNum) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subNum)));
      }
      return newArray;
    },
    /**
     * 计算手串子珠数量 用 手围 - 主珠直徑 * 主珠数量 / 主珠数量 / 子珠直徑 = 子珠数量 / 主珠数量 =  子珠在每一间隙中的数量
     */
    drawTestCircular(data, mainJewelryData) {
      let temp = this.group(
        data,
        Math.floor(data.length / this.mainJewelryNum)
      );
      temp = temp.splice(0, this.mainJewelryNum);
      this.drawEveryJewelry(temp, mainJewelryData);
    },
    // eslint-disable-next-line no-unused-vars
    drawEveryJewelry(data, mainJewelryData) {
      //判断能放多少颗珠子
      //（cm）*10 == mm  向下取整
      //子珠数量🟰 手围 * 10 - 主珠数量 * 主珠直徑 / 子珠尺寸
      let num = Math.floor(
        (this.handSize * 10 - this.mainJewelrySize * this.mainJewelryNum) /
          this.jewelrySize /
          this.mainJewelryNum
      );
      data.forEach((item, index) => {
        // 平均每一颗珠子所占空间 包括间隙
        let avgNum = this.jewelrySize * 3.14 * 10;
        // 加一颗珠子的直徑用来居中
        let star = Math.floor(
          (this.mainJewelrySize * 10 * 3.14) / 2 + avgNum / 2
        );
        let end = Math.floor(
          data[index].length - avgNum - this.mainJewelrySize * 31.4
        );
        let arr = data[index].splice(star, end);
        arr.forEach((item) => {
          var ctx = document.getElementById("canvas_1").getContext("2d");
          ctx.beginPath();
          ctx.arc(item.x, item.y, 1, 0, 2 * Math.PI);
          ctx.stroke();
        });

        avgNum = this.jewelrySize * 3.14 * 10;

        //起点 因为线段可能有空隙 检测最后一刻珠子和 下一颗主珠空隙多大，然后均分

        //线段长度 - 珠子总需长度 / 珠子数量 (arr.length - avgNum * num) / (num - 2)
        let gapDistance =
          (arr.length - this.jewelrySize * 3.14 * 10 * num) / num < 0
            ? 0
            : (arr.length - this.jewelrySize * 3.14 * 10 * num) / num;

        let startPoints =
          Math.floor(
            (arr.length -
              Number(
                (
                  Number(Math.abs(gapDistance.toFixed(0)).toFixed(0)) +
                  avgNum * (num - 1 == 0 ? 1 : num - 1) +
                  gapDistance * (num - 1 == 0 ? 1 : num - 1) -
                  1
                ).toFixed(0)
              )) /
              2
          ) < 0
            ? 0
            : Math.floor(
                (arr.length -
                  Number(
                    (
                      Number(Math.abs(gapDistance.toFixed(0)).toFixed(0)) +
                      avgNum * (num - 1 == 0 ? 1 : num - 1) +
                      gapDistance * (num - 1 == 0 ? 1 : num - 1) -
                      1
                    ).toFixed(0)
                  )) /
                  2
              );

        // console.log(
        //   'num',num,
        //   "线段长度",
        //   arr.length,
        //   "珠子长度",
        //   Number(
        //     (
        //       Number(Math.abs(gapDistance.toFixed(0)).toFixed(0)) +
        //       avgNum * (num - 1 == 0 ? 1 : num - 1) +
        //       gapDistance * (num - 1 == 0 ? 1 : num - 1) -
        //       1 - avgNum
        //     ).toFixed(0)
        //   ),
        //   "珠子半径",
        //   Math.floor(avgNum / 2),
        //   "最后一颗子珠位置",
        //   (
        //     Number(Math.abs(gapDistance.toFixed(0)).toFixed(0)) +
        //     avgNum * (num - 1 == 0 ? 1 : num - 1) +
        //     gapDistance * (num - 1 == 0 ? 1 : num - 1) -
        //     1
        //   ).toFixed(0),
        //   'avgnum',avgNum,
        //   "还剩长度",
        //   arr.length -
        //     (Number(
        //       (
        //         Number(Math.abs(gapDistance.toFixed(0)).toFixed(0)) +
        //         avgNum * (num - 1 == 0 ? 1 : num - 1) +
        //         gapDistance * (num - 1 == 0 ? 1 : num - 1) -
        //         1
        //       ).toFixed(0)
        //     ) +
        //       Math.floor(avgNum / 2))
        // );
        for (let j = 0; j < num; j++) {
          var ctx = document.getElementById("canvas_1").getContext("2d");
          ctx.beginPath();
          ctx.strokeStyle = index == 0 ? "red" : "blue";
          console.log(arr);

          // console.log(
          //   "arrlength",
          //   arr.length,
          //   j == 0
          //     ? (Math.abs(gapDistance.toFixed(0)) + startPoints).toFixed(0)
          //     : (
          //         Number(
          //           (Math.abs(gapDistance.toFixed(0)) + startPoints).toFixed(0)
          //         ) +
          //         avgNum * j +
          //         gapDistance * j -
          //         2
          //       ).toFixed(0)
          // );

          let temp =
            arr[
              j == 0
                ? (Math.abs(gapDistance.toFixed(0)) + startPoints).toFixed(0)
                : (
                    Number(
                      (Math.abs(gapDistance.toFixed(0)) + startPoints).toFixed(
                        0
                      )
                    ) +
                    avgNum * j +
                    gapDistance * j -
                    2
                  ).toFixed(0)
            ];
          // * 2是因为需要1px = 4mm n*2 = 4mm下的半径
          ctx.arc(temp.x, temp.y, this.jewelrySize * 2, 0, 2 * Math.PI);
          ctx.stroke();
        }
      });
    },
    drawMainJewelry(data) {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      for (let i = 0; i < data.length; i++) {
        ctx.beginPath();
        ctx.arc(data[i].x, data[i].y, this.mainJewelrySize * 2, 0, 2 * Math.PI);
        ctx.stroke();
      }
    },
    drawCirc() {
      var ctx = document.getElementById("canvas_1").getContext("2d");
      ctx.beginPath();
      let radius = (this.handSize * 40) / 3.14 / 2;
      ctx.arc(200, 200, radius, 0, 2 * Math.PI);
      ctx.stroke();
    },
    /**
     *  nodeNum 等分圆的点数
     *  center 圆心 {x,y}
     *  radius 半径 （直徑 该处为bug
     *  return 每个元素的坐标 [{x,y},{x,y},...]
     */
    calcCircularLayout(nodeNum, center, radius) {
      radius = (radius * 40) / 3.14 / 2;
      var i,
        _i,
        _layouts = [];
      for (i = _i = 0; _i < nodeNum; i = ++_i) {
        var x = center.x + radius * Math.sin((2 * Math.PI * i) / nodeNum),
          y = center.y + radius * Math.cos((2 * Math.PI * i) / nodeNum);
        _layouts.push({ x: x, y: y });
      }
      return _layouts;
    },
    checkCircle(circleB, circleA) {
      var circleRadiusTotal = circleA.radius + circleB.radius;
      var dx = circleA.x - circleB.x;
      var dy = circleA.y - circleB.y;
      var distance = Math.sqrt(dx * dx + dy * dy);
      return distance <= circleRadiusTotal;
    },
    /** p1: 圆上的坐标点[x,y]
     *  r: 半径
     */
    getArcPath(p1, p2, r, sweepFlag = 1) {
      let L = Math.abs(p1[0] - p2[0]);
      let rotateL = -(p1[1] - p2[1]);
      if (sweepFlag === 0) L = -L;
      return `a${r},${r} 0 0,${sweepFlag} ${L},${rotateL}`; // 对比圆的特性
    },
  },
};
</script>

<style>
</style>