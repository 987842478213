import http from '@/utils/http'

export function getMaterials(params){
    return http('/materials', {params})
}
 
export function createOrders(data) {
    return http.post('/orders', data)
}

export function getMaterialDetails(id){
    return http(`/materials/${id}`)
}

