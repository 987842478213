<template>
  <div id="bracelets-size">
    <h2>サイズ：</h2>
    <p>手首サイズ＋1.5cmが適したサイズです。<span>（ゆったり：＋2cm）</span></p>
    <ul id="bracelets-size-panel">
      <li v-for="(item, index) in sizes" :key="index">
        <button
          :class="current == index ? 'current_size' : ''"
          @click="selectSize(index)"
        >
          {{ item }}cm
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import eventBus from "@/components/eventBus.js";
export default {
  data() {
    return {
      current: null,
      sizes: []
    };
  },
  methods: {
    selectSize(index) {
      this.current = index;
      eventBus.sizeIndex = index;
      eventBus.$emit('toggleSize')
    }
  },
  mounted() {
    this.sizes = eventBus.bracelets.map((item) => item.size);
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  #bracelets-size {
    margin-top: 0 !important;
  }

  #bracelets-size-panel {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    font-size: 0.8rem !important;
  }
}

#bracelets-size {
  background-color: #efebe3;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
}

#bracelets-size-panel {
  display: grid;
  gap: 0.5rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  display: inline-block;
  white-space: nowrap;
}
button {
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background-image: none;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-style: solid;
  border-width: 2px;
  padding: 0.25rem;
  width: 100%;
}

.current_size {
  border-color: #fb6e81;
  color: #fb6e81;
  border-style: dashed;
  border-width: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
</style>