<template>
  <div class="make-bracelet-step">
    <makeBraceletTitle></makeBraceletTitle>
    <div v-if="!$store.getters.isPc" class="make-bracelet-phone">
      <div class="title-box">
        <titleImg></titleImg>
      </div>
      <el-row :gutter="20" class="button-group step-button-group">
        <el-col :span="6" class="step-list" @click.native="onSetPreview(15)"
          ><span class="step-text">·15cm·</span>
          <img
            v-if="preview == 15"
            src="@/assets/images/step_1-button-selected.png"
          />
          <img v-else src="@/assets/images/step_1-button.png" />
        </el-col>
        <el-col :span="6" class="step-list" @click.native="onSetPreview(17)"
          ><span class="step-text">·17cm·</span
          ><img
            v-if="preview == 17"
            src="@/assets/images/step_1-button-selected.png" />
          <img v-else src="@/assets/images/step_1-button.png"
        /></el-col>
        <el-col :span="6" class="step-list" @click.native="onSetPreview(19)"
          ><span class="step-text">·19cm·</span
          ><img
            v-if="preview == 19"
            src="@/assets/images/step_1-button-selected.png" />
          <img v-else src="@/assets/images/step_1-button.png"
        /></el-col>
        <el-col :span="6" class="step-list" @click.native="onSetPreview(21)"
          ><span class="step-text">·21cm·</span
          ><img
            v-if="preview == 21"
            src="@/assets/images/step_1-button-selected.png" />
          <img v-else src="@/assets/images/step_1-button.png"
        /></el-col>
      </el-row>
      <div class="title-preview">
        <titleImg
          top="手链預覽"
          :bottom="`${braceletType($store.getters.mainJewelryNum)}（手圍${preview}cm）`"
        ></titleImg>
      </div>
      <div class="make-bracelet-content">
        <div class="make-bracelet-content-img-box">
          <img class="make-bracelet-content-img-sheji" width="100%" src="@/assets/images/sheji.png" />
          <img width="100%" src="@/assets/images/handsize.png" />
        </div>
      </div>
      <div class="title-preview">
        <titleImg top="測量手圍" bottom="尺寸方法"></titleImg>
      </div>
      <div class="make-bracelet-hand-around">
        <img src="@/assets/images/wei.png" />
      </div>
      <div class="make-bracelet-hand-around">
        <img src="@/assets/images/uii-top.png" />
        <div class="make-bracelet-hand-content">
          <div class="make-bracelet-hand-content-text">
            找到手腕最細部位。將軟尺前段邊界放置於手腕中央，用記號筆打上記號。軟尺沿著手腕外繞一圈，在記號處交匯，標記交匯處的数位，這個數位就是手腕周長。
          </div>
        </div>
        <img src="@/assets/images/uii-bottom.png" />
      </div>
      <div class="button-group-box">
        <el-row :gutter="50" class="button-group">
          <el-col :span="12"
            ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
          /></el-col>
          <el-col :span="12"
            ><img @click="onNextStep" src="@/assets/images/next-step.png"
          /></el-col>
        </el-row>
      </div>
    </div>
    <div v-else class="make-bracelet-pc">
       <el-row :gutter="20">
          <el-col :span="12">
            <div class="title-preview">
              <titleImg></titleImg>
            </div>
            <el-row :gutter="20" class="button-group step-button-group">
            <el-col :span="5" class="step-list" @click.native="onSetPreview(15)"
              ><span class="step-text">·15cm·</span>
              <img
                v-if="preview == 15"
                src="@/assets/images/step_1-button-selected.png"
              />
              <img v-else src="@/assets/images/step_1-button.png" />
            </el-col>
            <el-col :span="5" class="step-list" @click.native="onSetPreview(17)"
              ><span class="step-text">·17cm·</span
              ><img
                v-if="preview == 17"
                src="@/assets/images/step_1-button-selected.png" />
              <img v-else src="@/assets/images/step_1-button.png"
            /></el-col>
            <el-col :span="5" class="step-list" @click.native="onSetPreview(19)"
              ><span class="step-text">·19cm·</span
              ><img
                v-if="preview == 19"
                src="@/assets/images/step_1-button-selected.png" />
              <img v-else src="@/assets/images/step_1-button.png"
            /></el-col>
            <el-col :span="5" class="step-list" @click.native="onSetPreview(21)"
              ><span class="step-text">·21cm·</span
              ><img
                v-if="preview == 21"
                src="@/assets/images/step_1-button-selected.png" />
              <img v-else src="@/assets/images/step_1-button.png"
            /></el-col>
            </el-row>
            <div class="title-preview">
              <titleImg top="測量手圍" bottom="尺寸方法"></titleImg>
            </div>
            <div class="make-bracelet-hand-around">
                <img src="@/assets/images/uii-top.png" />
                <div class="make-bracelet-hand-content">
                  <div class="make-bracelet-hand-content-text">
                    找到手腕最細部位。將軟尺前段邊界放置於手腕中央，用記號筆打上記號。軟尺沿著手腕外繞一圈，在記號處交匯，標記交匯處的数位，這個數位就是手腕周長。
                  </div>
                </div>
                <img src="@/assets/images/uii-bottom.png" />
            </div>
            <div class="make-bracelet-hand-around">
              <img src="@/assets/images/wei.png" />
            </div>
          </el-col>
          <el-col :span="12">
              <div class="title-preview">
                <titleImg
                  top="手链預覽"
                  :bottom="`${braceletType(this.$store.getters.mainJewelryNum)}（手圍${preview}cm）`"
                ></titleImg>
              </div>
              <div class="make-bracelet-content">
                <div class="make-bracelet-content-img-box">
                  <img class="make-bracelet-content-img-sheji" width="100%" src="@/assets/images/sheji.png" />
                  <img width="100%" src="@/assets/images/handsize.png" />
                </div>
              </div>
          </el-col>
       </el-row>
       <div class="button-group-box">
        <el-row :gutter="50" type="flex" align="middle" justify="center" class="button-group">
          <el-col :span="5"
            ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
          /></el-col>
          <el-col :span="5"
            ><img @click="onNextStep" src="@/assets/images/next-step.png"
          /></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import titleImg from "../components/titleImg.vue";
import makeBraceletTitle from "./makeBraceletTitle.vue";
export default {
  components: { titleImg, makeBraceletTitle },
  computed: {
    preview() {
      return this.$store.getters.preview;
    },
  },
  methods: {
    onSetPreview(v) {
      this.$store.dispatch("setPreview", v);
      this.$forceUpdate();
    },
    onPreviousStep() {
      this.$store.dispatch("initial");
      this.$router.go(-1)
    },
    onNextStep() {
      this.$store.dispatch("setStep", 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.make-bracelet-phone{
  display: none;
}
.make-bracelet-step {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/bg_2.png");
  background-size: 100%;
  overflow: hidden;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.title-preview {
  margin: 1.25rem 0;
}
.make-bracelet-img {
  width: 12.5rem;
}
.button-group-box {
  overflow: hidden;
  padding: 1.25rem;
}
.step-button-group {
  padding: 0 1.25rem;
  margin-top: 1.25rem;
}
.step-button-group img {
   display: block;
   width: 100%;
}
.step-list {
  position: relative;
}
.step-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
}
.make-bracelet-content-img-box{
  position: relative;
  background: rgba(255, 255, 255, 0.7);
}
.make-bracelet-content-img-sheji{
  position: absolute;
  width: 3.125rem;
  top: 0.625rem;
  left: 0.625rem;
}
.make-bracelet-hand-around img {
  display: block;
  width: 100%;
  padding: 1.25rem;
}
.make-bracelet-hand-around img:first-child {
  padding-bottom: 0;
}
.make-bracelet-hand-around img:last-child {
  padding-top: 0;
}
.make-bracelet-hand-content {
    position: relative;
    border: 4px solid #eaca24;
    border-radius: 0.625rem;
    background-color: #ffe7ca;
    padding: 1.25rem;
    margin: 0 1.25rem;
  }
  .make-bracelet-hand-around img {
    display: block;
    width: 100%;
    padding: 1.25rem;
  }
.make-bracelet-content {
  background: rgba(255, 255, 255, 0.0);
  border-radius: 0.625rem;
  border: 2px dashed;
  // height: 400px;
}
.button-group-box img {
  display: block;
  width: 12.5rem;
  margin: 0 auto;
}
/*在768 和991 像素之间的屏幕里，小屏幕，主要是PAD*/
@media (min-width: $phoneMinWidth) and (max-width: $phoneMaxWidth) {
  .make-bracelet-pc{
    display: none;
  }
  .make-bracelet-phone{
  display: block;
  }
  .title-preview {
    margin: 1.25rem 0;
  }
  .make-bracelet-img {
    width: 12.5rem;
  }
  .button-group-box {
    overflow: hidden;
    padding: 1.25rem;
  }
  .step-button-group {
    padding: 0 1.25rem;
    margin-top: 1.25rem;
  }
  .step-list {
    position: relative;
  }
  .step-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.875rem;
  }
  .make-bracelet-hand-content {
    position: relative;
    border: 4px solid #eaca24;
    border-radius: 0.625rem;
    background-color: #ffe7ca;
    padding: 1.25rem;
    margin: 0 1.25rem;
  }
  .make-bracelet-hand-around img {
    display: block;
    width: 100%;
    padding: 1.25rem;
  }
  .make-bracelet-hand-around img:first-child {
    padding-bottom: 0;
  }
  .make-bracelet-hand-around img:last-child {
    padding-top: 0;
  }
  .make-bracelet-content {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 0.625rem;
    border: 2px dashed;
    // height: 400px;
  }
  .button-group img {
    display: block;
    width: 100%;
  }
}
</style>