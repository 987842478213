<template>
  <div class="bracelets-detail">
    <h2>合計</h2>
    <div id="lz-result-panel-price">
      ￥8,420 → <strong>￥5,770</strong>(税込)
    </div>
    <div id="lz-result-parts-list">
      <ul v-for="(item, index) in getDetails" :key="index">
        <li>
          {{ item.name }} {{ item.size / 4 }}mm [ {{ item.sum }}個 ]
          <span style="float: right">￥5,770</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import eventBus from "@/components/eventBus.js";
export default {
  data() {
    return {
      details: [],
      currBraceletSizeIndex: 0,
    };
  },
  computed: {
    getDetails() {
      let model = this.details[this.currBraceletSizeIndex]?.model ?? [];

      let data = [];

      for (let i = 0; i < model.length; i++) {
        let flag = data.findIndex(
          (item) => item.id === model[i].id + "|" + model[i].size
        );
        if (flag < 0) {
          data.push({
            id: model[i].id + "|" + model[i].size,
            name: model[i].name,
            size: model[i].size,
            price: model[i].price,
            sum: 1,
          });
        } else {
          data[flag].sum += 1;
        }
      }

      return data;
    },
  },
  created() {
    eventBus.$on("onBraceletsChange", () => {
      this.details = eventBus.bracelets;
    });

    eventBus.$on("toggleSize", () => {
      this.currBraceletSizeIndex = eventBus.sizeIndex;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/theme.scss";
.bracelets-detail {
  border-color: $theme-bd-color;
  color: $theme-text-color;
  border-width: 1px;
  border-style: solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bracelets-detail h2 {
  color: $theme-title-color;
  border-color: $theme-bd-color;
  border-style: solid;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  margin: 0;
}

#lz-result-panel-price {
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: right;
}

#lz-result-parts-list strong {
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

#lz-result-panel-price strong {
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
#lz-result-parts-list {
  border-color: #efebe3;
  border-style: double;
  border-width: 4px;
  padding: 0.5rem;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>