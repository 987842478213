<template>
  <div class="make-bracelet-step">
    <makeBraceletTitle></makeBraceletTitle>
    <div v-if="!$store.getters.isPc" class="make-bracelet-phone">
      <div class="title-box">
        <titleImg top="選擇主珠" :bottom="`直徑尺寸`"></titleImg>
      </div>
      <el-row :gutter="20" class="button-group step-button-group">
        <el-col :span="8" class="step-list" @click.native="onSetDiameter(8)"
          ><span class="step-text">·8mm·</span>
          <img
            v-if="diameter == 8"
            src="@/assets/images/step_2-button-selected.png"
          />
          <img v-else src="@/assets/images/step_2-button.png" />
        </el-col>
        <el-col :span="8" class="step-list" @click.native="onSetDiameter(10)"
          ><span class="step-text">·10mm·</span
          ><img
            v-if="diameter == 10"
            src="@/assets/images/step_2-button-selected.png" />
          <img v-else src="@/assets/images/step_2-button.png"
        /></el-col>
        <el-col :span="8" class="step-list" @click.native="onSetDiameter(12)"
          ><span class="step-text">·12mm·</span
          ><img
            v-if="diameter == 12"
            src="@/assets/images/step_2-button-selected.png" />
          <img v-else src="@/assets/images/step_2-button.png"
        /></el-col>
      </el-row>
      <div class="title-preview">
        <titleImg
          top="手链預覽"
          :bottom="`${braceletType($store.getters.mainJewelryNum)}（手圍${$store.getters.preview}cm）`"
        ></titleImg>
      </div>
      <div class="make-bracelet-content make-bracelet-content-img-box">
        <img class="make-bracelet-content-img-sheji" width="100%" src="@/assets/images/sheji.png" />
        <bracelet class="make-bracelet-content-bracelet" :handSize="handsize" :step="1" />
      </div>
      <div class="title-preview">
        <titleImg top="水晶能量" bottom="選擇"></titleImg>
      </div>
      <div class="make-bracelet-hand-around">
        <chooseCrystal :step="2"></chooseCrystal>
      </div>
      <div class="make-bracelet-hand-around">
        <stepIntroduce :step="2"></stepIntroduce>
      </div>
      <div class="make-bracelet-hand-around">
        <numberCombined :step="2"></numberCombined>
      </div>
      <div class="button-group-box">
        <el-row :gutter="50" class="button-group">
          <el-col :span="12"
            ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
          /></el-col>
          <el-col :span="12"
            ><img @click="onNextStep" src="@/assets/images/next-step.png"
          /></el-col>
        </el-row>
      </div>
    </div>
    <div v-else class="make-bracelet-pc">
        <el-row :gutter="20" >
          <el-col :span="6">
                <div class="title-box">
                  <titleImg top="選擇主珠" :bottom="`直徑尺寸`"></titleImg>
                </div>
                <el-row :gutter="20" class="button-group step-button-group">
        <el-col :span="8" class="step-list" @click.native="onSetDiameter(8)"
          ><span class="step-text">·8mm·</span>
          <img
            v-if="diameter == 8"
            src="@/assets/images/step_2-button-selected.png"
          />
          <img v-else src="@/assets/images/step_2-button.png" />
        </el-col>
        <el-col :span="8" class="step-list" @click.native="onSetDiameter(10)"
          ><span class="step-text">·10mm·</span
          ><img
            v-if="diameter == 10"
            src="@/assets/images/step_2-button-selected.png" />
          <img v-else src="@/assets/images/step_2-button.png"
        /></el-col>
        <el-col :span="8" class="step-list" @click.native="onSetDiameter(12)"
          ><span class="step-text">·12mm·</span
          ><img
            v-if="diameter == 12"
            src="@/assets/images/step_2-button-selected.png" />
          <img v-else src="@/assets/images/step_2-button.png"
        /></el-col>
                </el-row>
                 <div class="title-preview">
                    <titleImg top="水晶能量" bottom="選擇"></titleImg>
                  </div>
                  <div class="make-bracelet-hand-around">
                    <chooseCrystal :step="2"></chooseCrystal>
                  </div>
          </el-col>
          <el-col :span="10"> 
            <div class="make-bracelet-content make-bracelet-content-img-box">
              <img class="make-bracelet-content-img-sheji" width="100%" src="@/assets/images/sheji.png" />
              <bracelet class="make-bracelet-content-bracelet" :handSize="handsize" :step="1" />
            </div>
          </el-col>
          <el-col :span="8">
              <div class="make-bracelet-hand-around">
                  <stepIntroduce :step="2"></stepIntroduce>
              </div>
              <div class="make-bracelet-hand-around">
                <numberCombined :step="2"></numberCombined>
              </div>
          </el-col>
        </el-row>
        <div class="button-group-box">
          <el-row :gutter="50" type="flex" align="middle" justify="center"  class="button-group">
            <el-col :span="5"
              ><img @click="onPreviousStep" src="@/assets/images/previous-step.png"
            /></el-col>
            <el-col :span="5"
              ><img @click="onNextStep" src="@/assets/images/next-step.png"
            /></el-col>
          </el-row>
        </div>
    </div>
  </div>
</template>

<script>
import makeBraceletTitle from "./makeBraceletTitle.vue";
import titleImg from "../components/titleImg.vue";
import numberCombined from "../components/numberCombined.vue";
import stepIntroduce from "../components/stepIntroduce.vue";
import chooseCrystal from "../components/chooseCrystal.vue";
import Bracelet from "../../createBracelets/bracelet.vue";
export default {
  components: {makeBraceletTitle, titleImg, stepIntroduce, chooseCrystal, Bracelet,numberCombined },
  computed: {
    handsize() {
      return this.$store.getters.preview;
    },
    diameter() {
      return this.$store.getters.diameter;
    },
  },
  methods: {
    onSetDiameter(v) {
      this.$store.dispatch("setDiameter", v);
      this.$forceUpdate();
    },
    onPreviousStep() {
      this.$store.dispatch("setStep", 0);
    },
    onNextStep() {
      this.$store.dispatch("setStep", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.make-bracelet-step {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/bg_3.png");
  background-size: 100%;
  overflow: hidden;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.make-bracelet-phone{
  display: none;
}
.title-preview {
  margin: 1.25rem 0;
}
.make-bracelet-img {
  width: 12.5rem;
}
.button-group-box {
  overflow: hidden;
  padding: 1.25rem;
}
.step-button-group {
  padding: 0 1.25rem;
  margin-top: 1.25rem;
}
.step-list {
  position: relative;
}
.step-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.make-bracelet-hand-around {
  margin: 0 1.25rem;
  margin-bottom: 1.25rem;
}
.make-bracelet-content-img-box{
  position: relative;
}
.make-bracelet-content-bracelet{
  margin: 0 auto !important;
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, 0);
}
.make-bracelet-content-img-sheji{
  position: absolute;
  width: 3.125rem;
  top: 0.625rem;
  left: 0.625rem;
}
.make-bracelet-content {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border: 2px dashed;
  height: 400px;
}
.button-group img {
  display: block;
  width: 100%;
}
.button-group-box img {
  display: block;
  width: 12.5rem;
  margin: 0 auto;
}
@media (min-width: $phoneMinWidth) and (max-width: $phoneMaxWidth) {
.make-bracelet-pc{
  display: none;
}
.make-bracelet-phone{
  display: block;
}
.title-preview {
  margin: 1.25rem 0;
}
.make-bracelet-img {
  width: 12.5rem;
}
.button-group-box {
  overflow: hidden;
  padding: 1.25rem;
}
.step-button-group {
  padding: 0 1.25rem;
  margin-top: 1.25rem;
}
.step-list {
  position: relative;
}
.step-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.make-bracelet-hand-around {
  margin: 0 1.25rem;
  margin-bottom: 1.25rem;
}
.make-bracelet-content-img-box{
  position: relative;
}
.make-bracelet-content-img-sheji{
  position: absolute;
  width: 3.125rem;
  top: 0.625rem;
  left: 0.625rem;
}
.make-bracelet-content {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border: 2px dashed;
  height: 400px;
}
.button-group img {
  display: block;
  width: 100%;
}
}
</style>