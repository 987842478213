<template>
  <div class="step-introduce-box">
    <img
      class="step-introduce-top"
      src="@/assets/images/step-introduce-top.png"
    />
    <img
      class="step-introduce-in"
      src="@/assets/images/step-introduce-in.png"
    />
    <div class="step-introduce-title">
      見た目Check!<i class="el-icon-edit-outline"></i>
    </div>
    <div class="step-introduce-content">
       <el-row class="checkbox-group-list" type="flex" v-for="(item, index) in braceletMaterials" :key="index">
          <el-col :span="3"><i class="el-icon-edit-outline"></i></el-col>
          <el-col v-if="step == 3" :span="3" style="font-size: 10px;">
            <img class="crystal-image" :src="item.crystalImage" />
          </el-col>
          <el-col v-if="step == 3" :span="3" style="font-size: 10px;">{{ item.type |  typeFilters}}</el-col>
          <el-col :span="5" style="font-size: 10px;">
            <span>{{ item.name }}:</span>
          </el-col>
          <el-col class="step-introduce-describe"> {{ item.describe }}</el-col>
          <el-col :span="2"><div class="grid-content">{{index+1}}</div></el-col>
        </el-row>
    </div>
  </div>
</template>

<script>
import eventBus from "@/components/eventBus.js";
export default {
  filters:{
    typeFilters(v){
       return v==0 ? '主珠' : '子珠'
    }
  },
   props:{ 
     step: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      bracelets: [],
      braceletMaterials: [],
      currentSparName:''
    }
  },
  watch:{
    '$store.getters.mainCurrentSpar':{
       handler(){
         this.getOrderList()
       },
       deep:true
    },
  },
  created() {
    eventBus.$on('getJewelryData', () => {
      this.bracelets = eventBus.bracelets
      this.$store.dispatch("setJewelryData",  this.bracelets);
      this.getOrderList()
    })
  },  
  methods:{
    getOrderList() {
      let mainJewelryData = this.$store.getters.mainCurrentSpar;
      let braceletType = this.$store.getters.mainJewelryNum;
      this.braceletMaterials = [
        {
          type: 0,
          size: mainJewelryData.diameter,
          materialId: mainJewelryData.id,
          number: braceletType,
          price: mainJewelryData.price,
          crystalImage: mainJewelryData.crystalImage,
          describe: mainJewelryData.describe,
          name: mainJewelryData.name,
          index: 0,
        },
      ];
      if(this.step == 3){
      let jewelryData = this.bracelets;
      const group = {};

      jewelryData.forEach(function (o) {
        const temp = JSON.stringify(o.data.id);
        group[temp] = group[temp] || [];
        group[temp].push(o);
      });
     
      let keys = Object.keys(group);

      for (let index = 0; index < keys.length; index++) {
        this.braceletMaterials.push({
          type: 1,
          size: group[keys[index]][0].data.diameter,
          describe: group[keys[index]][0].data.describe,
          materialId: group[keys[index]][0].data.id,
          crystalImage: group[keys[index]][0].data.crystalImage,
          number: group[keys[index]].length,
          index: index + 1,
          name: group[keys[index]][0].data.name,
          price: group[keys[index]][0].data.price,
        });
      }
      }
      return this.braceletMaterials;
    },
  },
};
</script>

<style lang="scss" scoped>
.step-introduce-box {
  background-color: #fbf6ec;
  border: 10px solid #e6b0aa;
  border-radius: 20px;
}
.step-introduce-title {
  margin: 0 1.25rem;
  margin-top: 1.875rem;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}
.step-introduce-top {
  display: block;
  width: 6.25rem;
  margin: 0 auto;
}
.step-introduce-in {
  display: block;
  width: 9.375rem;
  margin: 0 auto;
  margin-top: 1.25rem;
}

.step-introduce-content {
  margin: 0 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 1.25rem 0.3125rem;
  border: 2px solid #e6b0aa;
}
.step-introduce-describe {
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 0.625rem;
}
.grid-content {
  width: 19px;
  height: 19px;
  background: #e6b0aa;
  border-radius: 50%;
  text-align: center;
  font-size: 0.625rem;
  flex: 1;
  color: #fff;
}
.checkbox-group-list{
  margin: 0.625rem 0;
}
.crystal-image{
  display: block;
  width: 20px;
}
::v-deep {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .el-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    margin-right: 0;
  }
  .el-checkbox__label {
    flex: 1;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #e6b0aa;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #e6b0aa;
    border-color: #e6b0aa;
  }
}
</style>