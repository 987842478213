<template>
  <div class="choose-crystal-content-box">
    <div class="choose-crystal-content-item" @click="onItem">
      <img class="choose-bg-top-img" src="@/assets/images/choose_bg_top.png" />
      <div class="choose-bg-top-text">·{{ value.top }}·</div>
      <img v-if="step == 2" class="choose-img-bg" src="@/assets/images/choose_bg_1.png" />
      <img v-else class="choose-img-bg" src="@/assets/images/choose_bg_2.png" />
    </div>
    <div v-show="isShow" class="choose-crystal-content-show">
      <div
        class="choose-crystal-content-item"
        v-for="(item, index) in value.children"
        :key="index"
      >
        <img
          class="choose-bg-top-img"
          src="@/assets/images/choose_bg_top.png"
        />
        <div class="choose-bg-top-text" @click="onItemLabel(value.top, item.label)">·{{ item.label }}·</div>
         <img v-if="step == 2" class="choose-img-bg" src="@/assets/images/choose_bg_1.png" />
          <img v-else class="choose-img-bg" src="@/assets/images/choose_bg_2.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: function () {},
    },
    showIndex: {
      type: String,
      default: '',
    },
  },
  computed:{
     isShow(){
       return this.$store.getters[`isShowStep${this.step}`] == this.showIndex
     }
  },
  methods:{
    onItemLabel(top,label){
       let item ={
         top: top,
         label: label,
       }
       this.$emit('itemLabel',item)
       this.setIsShow()
    },
    onItem(){
       this.setIsShow()
    },
    setIsShow(){
      if(this.$store.getters[`isShowStep${this.step}`] == this.showIndex){
        this.$store.dispatch(`setIsShowStep${this.step}`, null);
      }else {
         this.$store.dispatch(`setIsShowStep${this.step}`, this.showIndex);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.choose-crystal-content-box{
  position: relative;
}
.choose-crystal-content-show{
  position: absolute;
  top: 2.9922rem;
  left: 0;
  z-index: 2;
}
.choose-crystal-title {
  margin: 0.9375rem 0;
}
.choose-crystal-content {
  min-height: 400px;
  border-radius: 0.625rem;
  border: 3px dashed #6ad0a5;
  background-color: rgba(255, 255, 255, 0.84);
  padding: 0.5rem;
}
.col-list {
  margin-bottom: 0.625rem;
}
.choose-crystal-content-item {
  position: relative;
//   padding: 0.5rem;
}
.choose-bg-top-text {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.4375rem;
  color: #0e5A01;
}
.choose-bg-top-img {
  display: block;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.choose-img-bg {
  display: block;
  width: 100%;
}
</style>