const step = {
  state: {
    ///记录步骤
    step: 0,
    ///手围尺寸
    preview: 15,
    ///主珠直徑
    diameter: 8,
    //子珠直徑
    jewelrySize: 6,
    mainJewelryNum: '3',
    number: 1,
    //当前选择的晶石
    currentSpar: {},
    mainCurrentSpar: {},
    step2label: [],
    step3label: [],
    crystalList2: [],
    crystalList3: [],
    handmadePart: 0,
    jewelryData: [],
    isShowStep2: null,
    isShowStep3: null,
    isPc: false
  },
  mutations: {
    initial: (state) => {
      state.step = 0
      state.mainJewelryNum = 3
      state.preview = 15
      state.diameter = 8
      state.jewelryData = []
    },
    setStep: (state, step) => {
      state.step = step
    },
    setIsPc: (state, isPc) => {
      state.isPc = isPc
    },
    setCrystalList2: (state, crystalList2) => {
      state.crystalList2 = crystalList2
    },
    setCrystalList3: (state, crystalList3) => {
      state.crystalList3 = crystalList3
    },
    setIsShowStep2: (state, isShowStep2) => {
      state.isShowStep2 = isShowStep2
    },
    setIsShowStep3: (state, isShowStep3) => {
      state.isShowStep3 = isShowStep3
    },
    setNumber: (state, number) => {
      state.number = number
    },
    setPreview: (state, preview) => {
      state.preview = preview
    },
    setDiameter: (state, diameter) => {
      state.diameter = diameter
    },
    setStep2label: (state, step2label) => {
      state.step2label = step2label
    },
    setStep3label: (state, step3label) => {
      state.step3label = step3label
    },
    setCurrentSpar: (state, currentSpar) => {
      state.currentSpar = currentSpar
    },
    setMainCurrentSpar: (state, mainCurrentSpar) => {
      state.mainCurrentSpar = mainCurrentSpar
    },
    setMainJewelryNum: (state, mainJewelryNum) => {
      state.mainJewelryNum = mainJewelryNum
    },
    setJewelrySize: (state, jewelrySize) => {
      state.jewelrySize = jewelrySize
    },
    setHandmadePart: (state, handmadePart) => {
      state.handmadePart = handmadePart
    },
    setJewelryData: (state, jewelryData) => {
      state.jewelryData = jewelryData
    },
  },
  actions: {
    initial(context) {
      context.commit('initial')
    },
    setStep(context, step) {
      context.commit('setStep', step)
    },
    setIsPc(context, isPc) {
      context.commit('setIsPc', isPc)
    },
    setNumber(context, number) {
      context.commit('setNumber', number)
    },
    setCrystalList2(context, crystalList2) {
      context.commit('setCrystalList2', crystalList2)
    },
    setCrystalList3(context, crystalList3) {
      context.commit('setCrystalList3', crystalList3)
    },
    setIsShowStep2(context, isShowStep2) {
      context.commit('setIsShowStep2', isShowStep2)
    },
    setIsShowStep3(context, isShowStep3) {
      context.commit('setIsShowStep3', isShowStep3)
    },
    setPreview(context, preview) {
      context.commit('setPreview', preview)
    },
    setDiameter(context, diameter) {
      context.commit('setDiameter', diameter)
    },
    setStep2label(context, step2label) {
      context.commit('setStep2label', step2label)
    },
    setStep3label(context, step3label) {
      context.commit('setStep3label', step3label)
    },
    setCurrentSpar(context, currentSpar) {
      context.commit('setCurrentSpar', currentSpar)
    },
    setMainJewelryNum(context, mainJewelryNum) {
      context.commit('setMainJewelryNum', mainJewelryNum)
    },
    setMainCurrentSpar(context, mainCurrentSpar) {
      context.commit('setMainCurrentSpar', mainCurrentSpar)
    },
    setJewelrySize(context, jewelrySize) {
      context.commit('setJewelrySize', jewelrySize)
    },
    setHandmadePart(context, handmadePart) {
      context.commit('setHandmadePart', handmadePart)
    },
    setJewelryData(context, jewelryData) {
      context.commit('setJewelryData', jewelryData)
    }
  }
}

export default step