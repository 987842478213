<template>
  <div>
    <div class="choose-crystal-title">
      <el-input placeholder="" v-model="keywords" prefix-icon="el-icon-search" class="input-with-select">
        <el-button slot="append" @click="onRetrievalClick">檢索</el-button>
      </el-input>
    </div>
    <div class="choose-crystal-content">
      <template v-for="(item, index) in list">
        <el-row :gutter="8" :key="index + 'a'" v-if="index % 3 == 0" type="flex">
          <el-col :span="8" class="col-list" v-for="(v, j) in list.slice(index, index + 3)" :key="`${index}y` + j">
            <chooseCrystalItem :value="v" :step="step" :showIndex="`${index}y` + j" @itemLabel="itemLabel">
            </chooseCrystalItem>
          </el-col>
        </el-row>
      </template>
      <div class="choose-crystal-label">
        <div class="choose-crystal-label-title">您所選擇的是></div>
        <div class="choose-crystal-label-content">
          <el-tag size="mini" @close="close(index)" v-for="(item, index) in stepLabels" :key="index" closable
            type="success">{{ item.top }}:{{ item.label }}
          </el-tag>
        </div>
      </div>
      <template v-for="(item, index) in $store.getters[`crystalList${step}`]">
        <el-row :gutter="10" type="flex" :key="index + 'p'" v-if="index % 12 == 0">
          <el-col :span="2" v-for="(v, j) in $store.getters[`crystalList${step}`].slice(index, index + 12)"
            :key="`${index}y` + j" class="choose-crystal-el-col">
            <img class="choose-img-bg" :src="v.crystalImage" @click="onSparClick(v)" />
          </el-col>
        </el-row>
      </template>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import eventBus from "@/components/eventBus.js";
import { getMaterials, getMaterialDetails } from "@/api/material.js";
import chooseCrystalItem from "../components/chooseCrystalItem.vue";
export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
  components: { chooseCrystalItem },
  data() {
    return {
      listQuery: {
        keywords: null,
        chakra: null,
        healingKeyword: null,
        helpfulZodiac: null,
        diameter: this.step == 2 ? this.$store.getters.diameter : this.$store.getters.jewelrySize,
        color: null,
        birthdayMonth: null,
        helpfulConstellation: null,
        isSuper: null,
        isRecommend: null,
      },
      newItem: [],
      keywords: "",
      is: [],
      list: [
        {
          top: "全部",
          children: [],
        },
        {
          top: "七脈輪",
          children: [
            {
              label: "頂輪",
            },
            {
              label: "眉心輪",
            },
            {
              label: "喉輪",
            },
            {
              label: "心輪",
            },
            {
              label: "太陽輪",
            },
            {
              label: "臍輪",
            },
            {
              label: "海底輪",
            },
          ],
        },
        {
          top: "店長推薦",
          children: [
            {
              label: "月亮石",
            },
            {
              label: "海藍寶",
            },
            {
              label: "青金石",
            },
            {
              label: "黑曜石",
            },
            {
              label: "螢石",
            },
            {
              label: "黑髮晶",
            },
          ],
        },
        {
          top: "運程",
          children: [
            {
              label: "金運&商壳",
            },
            {
              label: "仕業運&人際關係",
            },
            {
              label: "戀愛運&良緣",
            },
            {
              label: "健康運&療し",
            },
            {
              label: "親人&家庭圓滿",
            },
            {
              label: "守護&厄払い",
            },
          ],
        },
        {
          top: "十二誕生石",
          children: [
            {
              label: "石榴石",
            },
            {
              label: "紫水晶",
            },
            {
              label: "舒俱萊",
            },
            {
              label: "海藍寶",
            },
            {
              label: "雞血石",
            },
            {
              label: "閃靈鑽",
            },
            {
              label: "白水晶",
            },
            {
              label: "砂金石",
            },
            {
              label: "祖母綠",
            },
            {
              label: "月光石",
            },
            {
              label: "天河石",
            },
            {
              label: "紅紋石",
            },
            {
              label: "紅寶石",
            },
            {
              label: "橄欖石",
            },
            {
              label: "纏絲瑪瑙",
            },
            {
              label: "青金石",
            },
            {
              label: "藍寶石",
            },
            {
              label: "黃水晶",
            },
            {
              label: "托帕石",
            },
            {
              label: "藍色托帕石",
            },
            {
              label: "綠松石",
            },
            {
              label: "鋯石",
            },
          ],
        },
        {
          top: "十二生肖",
          children: [
            {
              label: "鼠",
            },
            {
              label: "虎",
            },
            {
              label: "兔",
            },
            {
              label: "龍",
            },
            {
              label: "蛇",
            },
            {
              label: "馬",
            },
            {
              label: "羊",
            },
            {
              label: "猴",
            },
            {
              label: "雞",
            },
            {
              label: "狗",
            },
            {
              label: "猪",
            },
          ],
        },
        {
          top: "十二星座",
          children: [
            {
              label: "摩羯座",
            },
            {
              label: "水瓶座",
            },
            {
              label: "雙魚座",
            },
            {
              label: "白羊座",
            },
            {
              label: "金牛座",
            },
            {
              label: "雙子座",
            },
            {
              label: "巨蟹座",
            },
            {
              label: "獅子座",
            },
            {
              label: "處女座",
            },
            {
              label: "天秤座",
            },
            {
              label: "天蝎座",
            },
            {
              label: "射手座",
            },
          ],
        },
        {
          top: "超強能量",
          children: [
            {
              label: "超級七",
            },
            {
              label: "閃靈鑽",
            },
            {
              label: "黑金超",
            },
            {
              label: "銅髮晶",
            },
            {
              label: "紅紋石",
            },
            {
              label: "碧璽",
            },
            {
              label: "幽靈",
            },
            {
              label: "太赫茲",
            },
            {
              label: "拉長石",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.onCreateFun();
  },
  computed: {
    stepLabels() {
      return this.uniqueArr(this.unique(this.$store.getters[`step${this.step}label`]));
    },
  },
  watch: {
    stepLabels: {
      handler(v) {
        if (v != null) {
          if (v.length == 0) {
            this.listQuery.chakra = null,
              this.listQuery.healingKeyword = null,
              this.listQuery.helpfulZodiac = null,
              this.listQuery.diameter = null,
              this.listQuery.color = null,
              this.listQuery.birthdayMonth = null,
              this.listQuery.helpfulConstellation = null,
              this.listQuery.isSuper = null,
              this.listQuery.isRecommend = null
          }
          v.forEach((k) => {
            if (k.top == '七脈輪') {
              this.listQuery.chakra = k.label
            } else if (k.top == '運程') {
              this.listQuery.healingKeyword = k.label
            } else if (k.top == '十二生肖') {
              this.listQuery.helpfulZodiac = k.label
            } else if (k.top == '十二誕生石') {
              this.listQuery.birthdayMonth = k.label
            } else if (k.top == '十二星座') {
              this.listQuery.helpfulConstellation = k.label
            } else if (k.top == '') {
              this.listQuery.isSuper = k.label
            } else if (k.top == '') {
              this.listQuery.isRecommend = k.label
            }
          })
          this.getMaterials()
        }
      }
    },
    '$store.getters.diameter': {
      handler(v) {
        if (v != null) {
          this.getMaterials()
        }
      }
    },
    '$store.getters.jewelrySize': {
      handler(v) {
        if (v != null) {
          this.getMaterials()
        }
      }
    },
  },
  methods: {
    async onCreateFun() {
      store.dispatch(`setCrystalList${this.step}`, await getMaterials().then((result) => result.data))
      let detail = await getMaterialDetails(this.$store.getters[`crystalList${this.step}`][0].id).then((res) => res.data);
      if (this.step == 2) {
        store.dispatch("setMainCurrentSpar", detail);
      } else {
        eventBus.currentSpar = detail;
        eventBus.$emit("onSparSelect");
        store.dispatch("setCurrentSpar", detail);
      }
    },
    async onSparClick(spar) {
      let detail = await getMaterialDetails(spar.id).then((res) => res.data);
      if (this.step == 2) {
        // store.dispatch("setMainCurrentSpar", {});
        store.dispatch("setMainCurrentSpar", detail);

      } else {
        // store.dispatch("setCurrentSpar", {});
        eventBus.currentSpar = detail;
        eventBus.$emit("onSparSelect");
        store.dispatch("setCurrentSpar", detail);
      }
    },
    onRetrievalClick() {
      if (this.keywords !== '') {
        this.listQuery.keywords = this.keywords
        this.listQuery.chakra = null,
          this.listQuery.healingKeyword = null,
          this.listQuery.helpfulZodiac = null,
          this.listQuery.diameter = null,
          this.listQuery.color = null,
          this.listQuery.birthdayMonth = null,
          this.listQuery.helpfulConstellation = null,
          this.listQuery.isSuper = null,
          this.listQuery.isRecommend = null,
          this.getMaterials()
      }
    },
    close(index) {
      this.stepLabels.splice(index, 1);
      this.$store.dispatch(`setStep${this.step}label`, this.stepLabels);
    },
    itemLabel(item) {
      this.stepLabels.push(item);
      this.$store.dispatch(`setStep${this.step}label`, this.stepLabels);
    },
    unique(arr) {
      const obj = {};
      arr = arr.reduce((cur, next) => {
        obj[next.label] ? "" : (obj[next.label] = true && cur.push(next));
        return cur;
      }, []);
      return arr;
    },
    uniqueArr(arr) {
      let result = arr.reduce((unique, item) => {
        let key = item['top'];
        if (!unique.some(obj => obj['top'] === key)) {
          unique.push(item);
        } else {
          unique.push(item);
          let newIndex = unique.findIndex(k => k['top'] === item['top'])
          unique.splice(newIndex, 1)
        }
        return unique;
      }, []);
      return result
    },
    getMaterials() {
      let listQuery = {
        keywords: this.listQuery.keywords,
        chakra: this.listQuery.chakra,
        healingKeyword: this.listQuery.healingKeyword,
        helpfulZodiac: this.listQuery.helpfulZodiac,
        diameter: this.step == 2 ? this.$store.getters.diameter : this.$store.getters.jewelrySize,
        color: this.listQuery.color,
        birthdayMonth: this.listQuery.birthdayMonth,
        helpfulConstellation: this.listQuery.helpfulConstellation,
        isSuper: this.listQuery.isSuper,
        isRecommend: this.listQuery.isRecommend,
      }
      getMaterials(listQuery).then((v) => {
        this.materials = v.data
        store.dispatch(`setCrystalList${this.step}`, v.data)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.choose-crystal-title {
  margin: 0.9375rem 0;
}

.choose-crystal-content {
  min-height: 400px;
  border-radius: 10px;
  border: 3px dashed #6ad0a5;
  background-color: rgba(255, 255, 255, 0.84);
  padding: 0.5rem;
}

.col-list {
  margin-bottom: 0.625rem;
}

.choose-crystal-content-item {
  position: relative;
  padding: 0.5rem;
  color: #0e5A01;
}

.choose-bg-top-text {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.625rem;
  color: #0e5A01;
}

.choose-bg-top-img {
  display: block;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.choose-img-bg {
  display: block;
  width: 100%;
}

.choose-crystal-label {
  margin-bottom: 0.625rem;
}

.choose-crystal-label-title {
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 1.25rem;
  color: #fff;
  margin-bottom: 0.5rem;
  background-color: #388c6c;
  border: 1px solid #6ad0a5;
  color: #c1f4dc;
}

.choose-crystal-el-col {
  margin-bottom: 0.625rem;
}

::v-deep {
  .el-tag {
    margin: 0.375rem;
  }
}
</style>