import Vue from 'vue'
import VueRouter from 'vue-router'
import orderMade from '@/views/orderMade/index'
import createBracelets from '@/views/createBracelets/index'
import badge from '@/views/badge/index'
import makeBracelet from '@/views/make-bracelet'
import badgePreview from '@/views/badge/preview'
import uploadAvatar from '@/views/badge/uploadAvatar'

Vue.use(VueRouter)

const routes = [
  {
    path:'/', component: () => import('@/layout/index'), children : [

      {
        path: '/ordermade',
        name: 'ordermade',
        component: orderMade
      },
      {
        path: '/',
        name: 'makeBracelet',
        component: makeBracelet
      },
      {
        path: '/create',
        name: 'test',
        component: createBracelets
      },
      {
        path: '/badge',
        name: 'badge',
        component: badge
      },
      {
        path: '/badge/preview',
        name: 'badge-preview',
        component: badgePreview
      },
      {
        path: '/badge/uploadAvatar/:seed',
        name: 'badge-upload',
        component: uploadAvatar
      },

    ]
  },
 
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
