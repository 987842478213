<template>
    <div class="make-bracelet">
        <div class="make-bracelet-step-box">
            <step1 v-if="active ==0"></step1>
            <step2 v-if="active ==1"></step2>
            <step3 v-if="active ==2"></step3>
            <step4 v-if="active ==3"></step4>
        </div>
    </div>
</template>

<script>
import step1 from './components/step_1.vue'
import step2 from './components/step_2.vue'
import step3 from './components/step_3.vue'
import step4 from './components/step_4.vue'
    export default {
        data() {
            return {
                userAgent: navigator.userAgent.includes('Mobile')
            }
        },
        components: { step1, step2, step3, step4 },
        computed:{
            active(){
               return this.$store.getters.step
            },
        },
        created(){
            if(this.getUrlParam('mainJewelryNum')!=null){
                this.$store.dispatch("setMainJewelryNum", this.getUrlParam('mainJewelryNum'));
            }else {
                this.$store.dispatch("setMainJewelryNum", '3');
            }
        },
        methods:{
            getUrlParam (name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
                var r = window.location.search.substr(1).match(reg);
                if (r != null) return unescape(r[2]); return null;
            }
        }
    }
</script>